var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-comp",attrs:{"id":"multipleTree"}},[_c('div',{ref:"mulheight",staticClass:"inp-select",class:{ error: _vm.tip !== '' },style:({ width: _vm.width + 'px', height: _vm.height + 'px' }),on:{"click":function($event){$event.stopPropagation();return _vm.showOptions.apply(null, arguments)}}},[(_vm.allSelected.length === 0 && _vm.inputPlaceholder !== '')?_c('div',{staticClass:"show-placeholder"},[_c('span',[_vm._v(_vm._s(_vm.inputPlaceholder))])]):_vm._e(),_vm._l((_vm.allSelected),function(list,index){return _c('div',{staticClass:"show-frame"},[_c('span',[_vm._v(_vm._s(list.name))]),(!_vm.singleChoose && !list.closeDisabled)?_c('i',{staticClass:"sdnm-close",on:{"click":function($event){$event.stopPropagation();return _vm.deleteChoosed(index)}}}):_vm._e()])})],2),_c('i',{staticClass:"sdnm-unfold",on:{"click":function($event){$event.stopPropagation();return _vm.showOptions.apply(null, arguments)}}}),_c('div',{staticClass:"tip",class:{ hide: _vm.optionShow },style:({ top: _vm.selHeight - 3 + 'px' })},[_vm._v(" "+_vm._s(_vm.tip)+" ")]),(_vm.optionShow)?_c('div',{staticClass:"select-options",style:({ top: _vm.selHeight + 'px' })},[_c('div',{staticClass:"options"},[(_vm.showAllChoose)?_c('div',{staticClass:"all",on:{"click":_vm.chooseAll}},[_vm._v(" "+_vm._s(_vm.allChooseName)+" ")]):_vm._e(),_c('ul',{staticClass:"tree-content"},_vm._l((_vm.dataInfo),function(item,index){return _c('li',[(item.showAll)?_c('div',[(_vm.canChoose)?_c('span',{class:{
                  'choose-single': _vm.singleChoose,
                  'choose-more': !_vm.singleChoose,
                },on:{"click":function($event){return _vm.getChoose(item, 'cant')}}},[_vm._v(_vm._s(item.name))]):_vm._e(),(!_vm.canChoose && item.children.length === 0)?_c('span',{class:{
                  'choose-single': _vm.singleChoose,
                  'choose-more': !_vm.singleChoose,
                },on:{"click":function($event){return _vm.getChoose(item, 'cant')}}},[_vm._v(_vm._s(item.name))]):_vm._e(),(!_vm.canChoose && item.children.length !== 0)?_c('span',{staticClass:"choose-more"},[_vm._v(_vm._s(item.name))]):_vm._e()]):_vm._e(),_c('ul',{staticClass:"tree-content pdl16"},_vm._l((item.children),function(citem,cindex){return _c('li',[(citem.showAll)?_c('div',[(_vm.canChoose)?_c('span',{class:{
                      'choose-single': _vm.singleChoose,
                      'choose-more': !_vm.singleChoose,
                    },on:{"click":function($event){return _vm.getChoose(citem, 'can')}}},[_vm._v(_vm._s(citem.name))]):_vm._e(),(!_vm.canChoose && citem.children.length === 0)?_c('span',{class:{
                      'choose-single': _vm.singleChoose,
                      'choose-more': !_vm.singleChoose,
                    },on:{"click":function($event){return _vm.getChoose(citem, 'can')}}},[_vm._v(_vm._s(citem.name))]):_vm._e(),(!_vm.canChoose && citem.children.length !== 0)?_c('span',{staticClass:"choose-more"},[_vm._v(_vm._s(citem.name))]):_vm._e()]):_vm._e(),_c('ul',{staticClass:"tree-content pdl16"},_vm._l((citem.children),function(ccitem,ccindex){return _c('li',[(ccitem.showAll)?_c('div',[(_vm.canChoose)?_c('span',{class:{
                          'choose-single': _vm.singleChoose,
                          'choose-more': !_vm.singleChoose,
                        },on:{"click":function($event){return _vm.getChoose(ccitem, 'can')}}},[_vm._v(_vm._s(ccitem.name))]):_vm._e(),(!_vm.canChoose && ccitem.children.length === 0)?_c('span',{class:{
                          'choose-single': _vm.singleChoose,
                          'choose-more': !_vm.singleChoose,
                        },on:{"click":function($event){return _vm.getChoose(ccitem, 'can')}}},[_vm._v(_vm._s(ccitem.name))]):_vm._e(),(!_vm.canChoose && ccitem.children.length !== 0)?_c('span',{staticClass:"choose-more"},[_vm._v(_vm._s(ccitem.name))]):_vm._e()]):_vm._e(),_c('ul',{staticClass:"tree-content pdl16"},_vm._l((ccitem.children),function(cccitem,cccindex){return _c('li',[(cccitem.showAll)?_c('div',[(_vm.canChoose)?_c('span',{class:{
                              'choose-single': _vm.singleChoose,
                              'choose-more': !_vm.singleChoose,
                            },on:{"click":function($event){return _vm.getChoose(cccitem, 'can')}}},[_vm._v(_vm._s(cccitem.name))]):_vm._e(),(!_vm.canChoose && cccitem.children.length === 0)?_c('span',{class:{
                              'choose-single': _vm.singleChoose,
                              'choose-more': !_vm.singleChoose,
                            },on:{"click":function($event){return _vm.getChoose(cccitem, 'can')}}},[_vm._v(_vm._s(cccitem.name))]):_vm._e(),(!_vm.canChoose && cccitem.children.length !== 0)?_c('span',{staticClass:"choose-more"},[_vm._v(_vm._s(cccitem.name))]):_vm._e()]):_vm._e()])}),0)])}),0)])}),0)])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }