<template>
  <div id="multipleTree" class="is-comp">
    <!-- <input type="text" class="inp-select"
			:placeholder="inputPlaceholder"
			readonly :value="selected.name"
			@click.stop="showOptions"> -->
    <div
      class="inp-select"
      ref="mulheight"
      :style="{ width: width + 'px', height: height + 'px' }"
      :class="{ error: tip !== '' }"
      @click.stop="showOptions"
    >
      <div
        v-if="allSelected.length === 0 && inputPlaceholder !== ''"
        class="show-placeholder"
      >
        <span>{{ inputPlaceholder }}</span>
      </div>
      <div v-for="(list, index) in allSelected" class="show-frame">
        <span>{{ list.name }}</span>
        <i
          class="sdnm-close"
          @click.stop="deleteChoosed(index)"
          v-if="!singleChoose && !list.closeDisabled"
        ></i>
      </div>
    </div>
    <i class="sdnm-unfold" @click.stop="showOptions"></i>
    <div
      class="tip"
      :class="{ hide: optionShow }"
      :style="{ top: selHeight - 3 + 'px' }"
    >
      {{ tip }}
    </div>
    <div
      class="select-options"
      v-if="optionShow"
      :style="{ top: selHeight + 'px' }"
    >
      <div class="options">
        <div class="all" @click="chooseAll" v-if="showAllChoose">
          {{ allChooseName }}
        </div>
        <ul class="tree-content">
          <li v-for="(item, index) in dataInfo">
            <div v-if="item.showAll">
              <span
                @click="getChoose(item, 'cant')"
                v-if="canChoose"
                :class="{
                  'choose-single': singleChoose,
                  'choose-more': !singleChoose,
                }"
                >{{ item.name }}</span
              >
              <!-- <span class="choose-single" @click="getChoose(item)" v-if="canChoose && item.children.length === 0">{{item.name}}</span> -->
              <span
                :class="{
                  'choose-single': singleChoose,
                  'choose-more': !singleChoose,
                }"
                @click="getChoose(item, 'cant')"
                v-if="!canChoose && item.children.length === 0"
                >{{ item.name }}</span
              >
              <span
                class="choose-more"
                v-if="!canChoose && item.children.length !== 0"
                >{{ item.name }}</span
              >
            </div>
            <ul class="tree-content pdl16">
              <li v-for="(citem, cindex) in item.children">
                <div v-if="citem.showAll">
                  <span
                    @click="getChoose(citem, 'can')"
                    v-if="canChoose"
                    :class="{
                      'choose-single': singleChoose,
                      'choose-more': !singleChoose,
                    }"
                    >{{ citem.name }}</span
                  >
                  <span
                    :class="{
                      'choose-single': singleChoose,
                      'choose-more': !singleChoose,
                    }"
                    @click="getChoose(citem, 'can')"
                    v-if="!canChoose && citem.children.length === 0"
                    >{{ citem.name }}</span
                  >
                  <span
                    class="choose-more"
                    v-if="!canChoose && citem.children.length !== 0"
                    >{{ citem.name }}</span
                  >
                </div>
                <ul class="tree-content pdl16">
                  <li v-for="(ccitem, ccindex) in citem.children">
                    <div v-if="ccitem.showAll">
                      <span
                        :class="{
                          'choose-single': singleChoose,
                          'choose-more': !singleChoose,
                        }"
                        @click="getChoose(ccitem, 'can')"
                        v-if="canChoose"
                        >{{ ccitem.name }}</span
                      >
                      <span
                        :class="{
                          'choose-single': singleChoose,
                          'choose-more': !singleChoose,
                        }"
                        @click="getChoose(ccitem, 'can')"
                        v-if="!canChoose && ccitem.children.length === 0"
                        >{{ ccitem.name }}</span
                      >
                      <span
                        class="choose-more"
                        v-if="!canChoose && ccitem.children.length !== 0"
                        >{{ ccitem.name }}</span
                      >
                    </div>
                    <ul class="tree-content pdl16">
                      <li v-for="(cccitem, cccindex) in ccitem.children">
                        <div v-if="cccitem.showAll">
                          <span
                            v-if="canChoose"
                            :class="{
                              'choose-single': singleChoose,
                              'choose-more': !singleChoose,
                            }"
                            @click="getChoose(cccitem, 'can')"
                            >{{ cccitem.name }}</span
                          >
                          <span
                            :class="{
                              'choose-single': singleChoose,
                              'choose-more': !singleChoose,
                            }"
                            @click="getChoose(cccitem, 'can')"
                            v-if="!canChoose && cccitem.children.length === 0"
                            >{{ cccitem.name }}</span
                          >
                          <span
                            class="choose-more"
                            v-if="!canChoose && cccitem.children.length !== 0"
                            >{{ cccitem.name }}</span
                          >
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/multipleTree
 * @desc 传蔬农贸 下拉多选树
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-10-13
 * @copyright 2021
 */
// import { event } from '_/EVENTs';
import { parse } from "../modules/utils";
export default {
  name: "multipleTree",
  props: {
    width: {
      type: Number,
      default: 300,
    },
    choosedArray: {
      type: Array,
      default: function() {
        return [];
      },
    },
    choosedInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
    inputPlaceholder: {
      type: String,
      default: "",
    },
    dataInfo: {
      type: Array,
      default: [],
    },
    tip: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 80,
    },
    selHeight: {
      type: Number,
      default: 84,
    },
    canChoose: {
      type: Boolean,
      default: true,
    },
    singleChoose: {
      type: Boolean,
      default: false,
    },
    cantChooseFather: {
      type: Boolean,
      default: false,
    },
    showAllChoose: {
      type: Boolean,
      default: false,
    },
    allChooseName: {
      type: String,
      default: "全部",
    },
    returnObj: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionShow: false, // 是否显示下拉框
      selected: {}, // 当前被选中选项的数据
      allSelected: [], // 所有被选中选项的数据
      allSelectedId: [], // 所有被选中选项的id
      allData: [], // 所有的数据
    };
  },
  watch: {
    optionShow(newValue, oldValue) {
      if (newValue && !oldValue) {
        window.addEventListener("click", this.windowClick);
      } else {
        window.removeEventListener("click", this.windowClick);
      }
    },
    dataInfo(newValue, oldValue) {
      // console.log(newValue);
      if (newValue !== "" && newValue !== oldValue) {
        this.allData = parse(this.dataInfo);
      }
    },
    choosedInfo(newValue, oldValue) {
      if (newValue !== "") {
        this.setTreeId(newValue.id);
        this.selected = newValue;
      } else if (newValue === "") {
        this.setTreeId("");
        this.selected = {};
      }
    },
    choosedArray(newValue, oldValue) {
      if (newValue.length !== 0) {
        this.allSelected = [];
        this.allSelectedId = [];
        newValue.map((el) => {
          this.getChoose(el);
          this.changeStatus(el.id);
          return el;
        });
      } else if (newValue.length === 0) {
        if (this.allSelected.length !== 0) {
          this.allSelectedId.map((el) => {
            this.clean(el);
            return el;
          });
        }
        this.allSelected = [];
        this.allSelectedId = [];
      }
    },
  },
  methods: {
    /**
     * 组件界面点击事件处理
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    windowClick(event) {
      let className = event.target.className;
      if (
        className !== "choose-more" ||
        className === "choose-more choose-single"
      ) {
        if (this.optionShow) {
          this.optionShow = !true;
        }
      }
    },
    /**
     * 组件界面点击事件处理
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    getHeight() {
      // let divRect = this.$refs.mulheight.clientHeight;
    },
    /**
     * 编辑时更改状态
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     * @param  {int}   id
     */
    clean(id) {
      this.dataInfo.map((el) => {
        if (el.id === id) {
          el.showAll = true;
          if (el.children.length !== 0) {
            el.children.map((sel) => {
              sel.showAll = true;
              if (sel.children.length !== 0) {
                sel.children.map((ssel) => {
                  ssel.showAll = true;
                  if (ssel.children.length !== 0) {
                    ssel.children.map((sssel) => {
                      sssel.showAll = true;
                      return sssel;
                    });
                  }
                  return ssel;
                });
              }
              return sel;
            });
          }
        } else {
          if (el.children.length !== 0) {
            el.children.map((cel) => {
              if (cel.id === id) {
                cel.showAll = true;
                if (cel.children.length !== 0) {
                  cel.children.map((sel) => {
                    sel.showAll = true;
                    if (sel.children.length !== 0) {
                      sel.children.map((ssel) => {
                        ssel.showAll = true;
                        return ssel;
                      });
                    }
                    return sel;
                  });
                }
              } else {
                if (cel.children.length !== 0) {
                  cel.children.map((ccel) => {
                    if (ccel.id === id) {
                      ccel.showAll = true;
                      if (ccel.children.length !== 0) {
                        ccel.children.map((sccel) => {
                          sccel.showAll = true;
                          return sccel;
                        });
                      }
                    } else {
                      if (ccel.children.length !== 0) {
                        ccel.children.map((cccel) => {
                          if (cccel.id === id) {
                            cccel.showAll = true;
                          }
                          return cccel;
                        });
                      }
                    }
                    return ccel;
                  });
                }
              }
              return cel;
            });
          }
        }
        return el;
      });
    },
    /**
     * 编辑时更改状态
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     * @param  {int}   id
     */
    changeStatus(id) {
      this.dataInfo.map((el) => {
        if (el.id === id) {
          el.showAll = false;
          if (el.children.length !== 0) {
            el.children.map((sel) => {
              sel.showAll = false;
              if (sel.children.length !== 0) {
                sel.children.map((ssel) => {
                  ssel.showAll = false;
                  if (ssel.children.length !== 0) {
                    ssel.children.map((sssel) => {
                      sssel.showAll = false;
                      return sssel;
                    });
                  }
                  return ssel;
                });
              }
              return sel;
            });
          }
        } else {
          if (el.children.length !== 0) {
            el.children.map((cel) => {
              if (cel.id === id) {
                cel.showAll = false;
                if (cel.children.length !== 0) {
                  cel.children.map((sel) => {
                    sel.showAll = false;
                    if (sel.children.length !== 0) {
                      sel.children.map((ssel) => {
                        ssel.showAll = false;
                        return ssel;
                      });
                    }
                    return sel;
                  });
                }
              } else {
                if (cel.children.length !== 0) {
                  cel.children.map((ccel) => {
                    if (ccel.id === id) {
                      ccel.showAll = false;
                      if (ccel.children.length !== 0) {
                        ccel.children.map((sccel) => {
                          sccel.showAll = false;
                          return sccel;
                        });
                      }
                    } else {
                      if (ccel.children.length !== 0) {
                        ccel.children.map((cccel) => {
                          if (cccel.id === id) {
                            cccel.showAll = false;
                          }
                          return cccel;
                        });
                      }
                    }
                    return ccel;
                  });
                }
              }
              return cel;
            });
          }
        }
        return el;
      });
    },
    /**
     * 回调函数，返回选择的数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     * @param  {object, String}   obj type
     */
    getChoose(obj, type) {
      if (this.cantChooseFather && type === "cant") {
      } else {
        if (this.canChoose && !this.singleChoose) {
          if (obj.children.length !== 0) {
            let cid = [];
            let selectObj = [];
            obj.children.map((el) => {
              if (el.children.length !== 0) {
                el.children.map((cel) => {
                  if (cel.children.length !== 0) {
                    cel.children.map((ccel) => {
                      if (ccel.children.length !== 0) {
                        ccel.children.map((cccel) => {
                          cid.push(cccel.id);
                          return cccel;
                        });
                      }
                      cid.push(ccel.id);
                      return ccel;
                    });
                  }
                  cid.push(cel.id);
                  return cel;
                });
              }
              cid.push(el.id);
              return el;
            });
            this.allSelected.map((el) => {
              if (!cid.includes(el.id)) {
                console.log(el);
                selectObj.push(el);
              }
              return el;
            });
            obj.showAll = false;
            obj.children.map((el) => {
              if (el.children.length !== 0) {
                el.children.map((cel) => {
                  if (cel.children.length !== 0) {
                    cel.children.map((ccel) => {
                      if (ccel.children.length !== 0) {
                        ccel.children.map((cccel) => {
                          cccel.showAll = false;
                          return cccel;
                        });
                      }
                      ccel.showAll = false;
                      return ccel;
                    });
                  }
                  cel.showAll = false;
                  return cel;
                });
              }
              el.showAll = false;
              return el;
            });
            selectObj.push(obj);
            this.allSelected = parse(selectObj);
            this.allSelectedId = this.allSelected.map((el) => {
              return el.id;
            });
          } else {
            obj.showAll = false;
            this.allSelected.push(obj);
            this.allSelectedId.push(obj.id);
          }
          // this.$emit('sdnm-multiple-tree', this.allSelectedId, this.name);
        } else {
          // if(type === 'cant' && this.cantChooseFather){
          // 	this.allSelected = [];
          // 	this.allSelectedId = [''];
          // 	if(obj.name === '全部'){
          // 		this.$emit('sdnm-multiple-tree', this.allSelectedId, this.name);
          // 	}
          // }
          // else{
          // 	if(!this.singleChoose){
          // 		obj.showAll = false;
          // 	}
          // 	else{
          // 		this.allSelected = [];
          // 		this.allSelectedId = [];
          // 	}
          // 	this.allSelected.push(obj);
          // 	this.allSelectedId.push(obj.id);
          // }
          if (!this.singleChoose) {
            obj.showAll = false;
          } else {
            this.allSelected = [];
            this.allSelectedId = [];
          }
          this.allSelected.push(obj);
          this.allSelectedId.push(obj.id);
        }
        if (this.returnObj) {
          this.$emit("sdnm-multiple-tree", this.allSelected, this.name);
        } else {
          this.$emit("sdnm-multiple-tree", this.allSelectedId, this.name);
        }
      }
    },
    /**
     * 删除选择的选项
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     * @param  {int}   index 索引值
     */
    deleteChoosed(index) {
      this.dataInfo.map((el) => {
        if (el.id === this.allSelectedId[index]) {
          el.showAll = true;
          if (el.children.length !== 0) {
            el.children.map((sel) => {
              sel.showAll = true;
              if (sel.children.length !== 0) {
                sel.children.map((ssel) => {
                  ssel.showAll = true;
                  if (ssel.children.length !== 0) {
                    ssel.children.map((sssel) => {
                      sssel.showAll = true;
                      return sssel;
                    });
                  }
                  return ssel;
                });
              }
              return sel;
            });
          }
        } else {
          if (el.children.length !== 0) {
            el.children.map((cel) => {
              if (cel.id === this.allSelectedId[index]) {
                cel.showAll = true;
                if (cel.children.length !== 0) {
                  cel.children.map((sel) => {
                    sel.showAll = true;
                    if (sel.children.length !== 0) {
                      sel.children.map((ssel) => {
                        ssel.showAll = true;
                        return ssel;
                      });
                    }
                    return sel;
                  });
                }
              } else {
                if (cel.children.length !== 0) {
                  cel.children.map((ccel) => {
                    if (ccel.id === this.allSelectedId[index]) {
                      ccel.showAll = true;
                      if (ccel.children.length !== 0) {
                        ccel.children.map((sccel) => {
                          sccel.showAll = true;
                          return sccel;
                        });
                      }
                    } else {
                      if (ccel.children.length !== 0) {
                        ccel.children.map((cccel) => {
                          if (cccel.id === this.allSelectedId[index]) {
                            cccel.showAll = true;
                          }
                          return cccel;
                        });
                      }
                    }
                    return ccel;
                  });
                }
              }
              return cel;
            });
          }
        }
        return el;
      });
      this.allSelected.splice(index, 1);
      this.allSelectedId.splice(index, 1);
      if (this.returnObj) {
        this.$emit("sdnm-multiple-tree", this.allSelected, this.name);
      } else {
        this.$emit("sdnm-multiple-tree", this.allSelectedId, this.name);
      }
    },
    /**
     * 点击全选选项
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    chooseAll() {
      this.allSelected = [];
      this.allSelectedId = [""];
      if (this.returnObj) {
        this.$emit("sdnm-multiple-tree", this.allSelected, this.name);
      } else {
        this.$emit("sdnm-multiple-tree", this.allSelectedId, this.name);
      }
    },
    /**
     * 显示下拉框
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    showOptions() {
      this.optionShow = true;
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
#multipleTree {
  // .pos-r();
  white-space: pre; // 解决firefox 勾换行
  line-height: normal;
  .pdl16 {
    .pdl(16px) !important;
  }
  .tip {
    .fc(12px, #f84445);
    .pos-a(1, 81px, 0);
  }
  .hide {
    .dn();
  }
  .show-placeholder {
    .dib();
    .fc(12px, #525e6e);
    height: 100%;
    line-height: 26px;
    .pdl(5px);
  }
  .inp-select {
    .pdl(4px);
    .pdr(30px);
    .dib();
    width: 300px;
    overflow-y: auto;
    border: 1px solid #a0b9dc;
    .brdr(2px);
    + i {
      .va-t();
      .mgt(9.4px);
    }
    .show-frame {
      .dib();
      .bgc(#eceff3);
      .brdr(26px);
      .fc(12px, #525e6e);
      .pd(4px 8px 4px 8px);
      .mgl(4px);
      .mgt(2px);
      > i {
        .cs();
      }
    }
    &.error {
      .brd(1px, solid, #f84445);
    }
  }
  .select-options {
    .pos-a(10, 84px, 0);
    .bgc(#ffffff);
    box-shadow: 0 4px 6px 0 #aab1ba;
    .brdr(4px);
    .wh(300px, 200px);
    white-space: normal;
    .mgt(-4px);
    .pd(4px);
    > .search {
      width: 99.6%;
      .mgr(0);
    }
    > .options {
      .mgt(10px);
      .wh(100%, 180px);
      overflow-y: auto;
      > .all {
        .pdl(20px);
        .fc(12px);
        .cs();
      }
    }
  }
  .tree-content {
    .mgt(0px);
    .pdl(0px);
    > li > div {
      .hlh(25px, 25px);
    }
  }
}
</style>
