<template>
  <div class="wrapper" id="demoqjj">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    ></ctheader>
    <div class="content" ref="qjj">
      <tree :data="roles" :checked-id="checkedId" name="tree"></tree>
      <a class="btn" @click="toggleShow">上传图片</a>
      <my-upload
        field="img"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-model="show"
        :width="400"
        :height="400"
        url=""
        :noCircle="true"
        :params="params"
        :headers="headers"
        img-format="jpg"
      >
      </my-upload>
      <img :src="imgDataUrl" />
      搜索树<searchtree
        :searchOptions="fruits"
        :choosedInfo="choid"
        name="searchTree"
      ></searchtree>
      <file-upload
        v-model="files"
        :post-action="postAction"
        :data="data"
        :size="1024 * 1024"
        :timeout="6 * 1000"
        @input-file="inputFile"
        ref="upload"
      >
        Upload file
      </file-upload>
      <multipletree
        :dataInfo="categorys"
        :height="multHeight"
        :canChoose="false"
        :singleChoose="false"
        :choosedArray="choosedArray"
        inputPlaceholder="32452345"
      ></multipletree>
      <div class="node-switch">
        <div class="radio-box">
          <input type="checkbox" :checked="status" @click="editStatus" />
          <label></label>
          <div class="radio-mask"></div>
        </div>
      </div>
      <showImage
        url="/images/load/fail.png"
        :showBigImage="showIt"
        :closeCallBack="cancle"
      ></showImage>
      <img src="/images/load/fail.png" @click="showBig" />
      <load-button
        class="btn primary"
        name="loading按钮"
        :disabled="loadbtn.disabled"
        :callback="loadCallback"
      ></load-button>
    </div>
  </div>
</template>

<script>
/**
 * @module app/demo/qjj
 * @desc 传蔬农贸版后台 qijiajun组件例子
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */
// import myUpload from 'vue-image-crop-upload';
// import fileUpload from 'vue-upload-component';
// import tree from '@@/tree';
// import searchtree from '@@/searchtree';
// import multipletree from '@@/multipletree';
// import ctheader from '@@/ctheader';
// import showImage from '@@/showImage';
import tree from "../../components/tree";
import searchtree from "../../components/searchtree";
import multipletree from "../../components/multipletree";
import ctheader from "../../components/ctheader";
import showImage from "../../components/showImage";
import * as URL from "../../modules/URLs";
import { event } from "../../modules/EVENTs";
import { mapGetters, mapActions } from "vuex";
const getters = mapGetters([]);
const actions = mapActions([]);
export default {
  name: "demoqjj",
  computed: {
    ...getters,
  },
  components: { tree, ctheader, searchtree, multipletree, showImage },
  beforeRouteEnter(to, from, next) {
    next();
  },
  mounted() {
    this.choosedArray = [
      {
        id: 1,
        name: "国产水果",
        show: true,
        children: [],
      },
      // {
      // 	id: 4,
      // 	name: '进口水果',
      // 	show: true,
      // 	children: [
      // 		{
      // 			id: 5,
      // 			name: '西瓜',
      // 			children: [],
      // 		},
      // 	],
      // },
    ];
    this.fruits = [
      {
        id: 0,
        name: "水果",
        show: true,
        showAll: true,
        children: [
          {
            id: 1,
            name: "国产水果",
            show: true,
            children: [
              {
                id: 2,
                name: "带壳类",
                children: [],
              },
              {
                id: 3,
                name: "无核类",
                children: [],
              },
            ],
          },
          {
            id: 4,
            name: "进口水果",
            show: true,
            children: [
              {
                id: 5,
                name: "西瓜",
                children: [],
              },
            ],
          },
          {
            id: 6,
            name: "账号权限",
            show: true,
            children: [
              {
                id: 7,
                name: "角色管理",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 8,
        name: "蔬菜",
        show: true,
        showAll: true,
        children: [],
      },
    ];
    this.choid = {
      id: 2,
      name: "带壳类",
    };
    this.choid2 = {
      id: 5,
      name: "西瓜",
    };
  },
  created() {
    event.$on("sdnm-tree", this.tree);
    event.$on("sdnm-multiple-tree", this.multipleTree);
  },
  beforeDestroy() {
    event.$off("sdnm-tree", this.tree);
    event.$off("sdnm-multiple-tree", this.multipleTree);
  },
  data() {
    return {
      status: false,
      showIt: false,
      postAction: "/api/product/products",
      data: { action: "product.import.excel" },
      multHeight: 80, // 输入框高度
      choid: {
        id: 2,
        name: "带壳类",
      },
      choid2: {
        id: 1,
        name: "国产水果",
      },
      pageInfo: {
        titles: [
          {
            name: "戚佳俊的组件库",
          },
        ],
        backBtn: false,
        backPath: "",
        toPath: URL.DEMO_QJJ,
      },
      categorys: [
        {
          id: 0,
          name: "水果",
          show: true,
          showAll: true,
          children: [
            {
              id: 1,
              name: "国产水果",
              show: true,
              showAll: true,
              children: [
                {
                  id: 2,
                  name: "带壳类",
                  showAll: true,
                  children: [],
                },
                {
                  id: 3,
                  name: "无核类",
                  showAll: true,
                  children: [
                    {
                      id: 32,
                      name: "带类",
                      showAll: true,
                      children: [],
                    },
                    {
                      id: 33,
                      name: "无类",
                      showAll: true,
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              name: "进口水果",
              show: true,
              showAll: true,
              children: [],
            },
            {
              id: 6,
              name: "账号权限",
              show: true,
              showAll: true,
              children: [
                {
                  id: 7,
                  name: "角色管理",
                  showAll: true,
                  children: [],
                },
              ],
            },
          ],
        },
        {
          id: 8,
          name: "蔬菜",
          show: true,
          showAll: true,
          children: [],
        },
      ],
      choosedArray: [],
      fruits: [],
      roles: [
        {
          id: 0,
          name: "全部",
          show: true,
          showAll: true,
          children: [
            {
              id: 1,
              name: "区位",
              show: true,
              children: [],
            },
            {
              id: 2,
              name: "商户",
              show: true,
              children: [
                {
                  id: 3,
                  name: "商户管理",
                  children: [],
                },
                {
                  id: 4,
                  name: "合同模板管理",
                  children: [],
                },
              ],
            },
            {
              id: 5,
              name: "档口",
              show: true,
              children: [],
            },
            {
              id: 6,
              name: "商品",
              show: true,
              children: [],
            },
            {
              id: 7,
              name: "账号权限",
              show: true,
              children: [
                {
                  id: 8,
                  name: "账号管理",
                  children: [],
                },
                {
                  id: 9,
                  name: "角色管理",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      id: 0,
      show: false,
      imgDataUrl: "",
      params: {}, // 上传附带其他数据，格式"{k:v}"
      headers: {}, // 上传header设置，格式"{k:v}"
      files: [],
      idInfo: [],
      checkedId: "",
      loadbtn: {
        disabled: false,
      },
    };
  },
  // watch: {
  // 	files(newValue, oldValue){
  // 		console.log(newValue);
  // 	},
  // },
  methods: {
    ...actions,
    editStatus() {
      // todo 禁用启用
      console.log("2312");
      this.status = !this.status;
    },
    showBig() {
      this.showIt = true;
    },
    cancle() {
      this.showIt = false;
    },
    /**
     * 加载按钮回调方法
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    loadCallback() {
      this.loadbtn.disabled = true;
      setTimeout(() => {
        this.loadbtn.disabled = false;
      }, 300000);
    },
    /**
     * 保存
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    tree(el) {
      this.id = el.id;
      this.checkedId = el.id;
    },
    /**
     * 回传数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    multipleTree(el) {
      this.idInfo = el;
    },
    /**
     * 保存
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    updatetValue(value) {
      console.log(value);
    },
    /**
     * 保存
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        console.log(newFile);
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile) {
        // 更新文件

        // 开始上传
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);
        }

        // 上传进度
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // 上传错误
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
        }

        // 上传成功
        if (newFile.success !== oldFile.success) {
          console.log("success", newFile.success, newFile);
        }
      }
    },
    /**
     * 显示上传图片
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-10
     */
    toggleShow() {
      this.show = !this.show;
    },
    // inputFile(newFile, oldFile){
    // 	if(newFile && oldFile && !newFile.active && oldFile.active){
    // 		console.log('response', newFile.response);
    // 		if(newFile.xhr){
    // 			console.log('status', newFile.xhr.status);
    // 		}
    // 	}
    // },

    /**
     * 预处理
     * @param  Object|undefined   newFile 新的 File 对象 可以直接修改
     * @param  Object|undefined   oldFile 旧的 File 对象 只读
     * @param  Function           prevent 阻止修改
     * @return undefined
     */
    inputFilter(newFile, oldFile, prevent) {
      // if(newFile && !oldFile){
      // 	if(!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)){
      // 		return prevent()
      // 	}
      // }
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      this.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData   服务器返回数据，已进行json转码
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
</style>
